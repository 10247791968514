import React from 'react';
import { useRef } from 'react';
import { BsFillArrowDownCircleFill } from 'react-icons/bs';
import moon from '../assets/moon.jpg';
import land from '../assets/land.png';
import mountAllison from '../assets/mta.jpg';
import fr from '../assets/frenchflag.svg';
import nl from '../assets/dutchflag.svg';
import swiss from '../assets/swissflag1.svg';
import ca from '../assets/canadaflag.svg';
import Footer from "../components/Footer";
import { Parallax, ParallaxLayer } from '@react-spring/parallax';

const About = () => {

  const ref = useRef();

    return (
    <div name='about' className='app w-full h-fit justify-center text-black transition-all duration-700 dark:transition-all 
    dark:duration-500 dark:text-white z-0'>
      <Parallax pages={4} ref={ref} className='pb-[50px] w-full scrollbar-hide app object-cover text-black 
      transition-all duration-700 dark:transition-all dark:duration-500 dark:text-white z-0'>

      <ParallaxLayer
          sticky={{ start: 0, end: 4}}
          className='pt-28 pl-28 w-full md:justify-center sm:items-center z-10'
        >
          <div className=''>
            <span 
            className='transition-all duration-700 dark:transition-all dark:duration-500 dark:text-white 
                    text-black font-bold font-mono text-4xl inline border-b-4 border-blue-500'>
                    About Me</span>
          </div>  
        </ParallaxLayer>

        <ParallaxLayer offset={0.5} speed={0.5} onClick={() => ref.current.scrollTo(1)} className=''>
          <div className='grid grid-row-2 md:flex sm:gap-16'>
            <div className='md:w-full justify-center flex flex-wrap relative items-center transition-all duration-700 dark:transition-all 
            dark:duration-500 dark:text-white text-black font-bold font-mono m-8 text-2xl pb-24 realtive sm:pb-6 -z-10'>
              <img src={mountAllison} alt="Mount Allison" width="300" height="300" className='absolute -top-18 rounded-full z-0 scale-75 sm:scale-100'/>
            </div>
            <div className='md:w-full'>
              <span className='flex flex-wrap justify-center items-center transition-all duration-700 dark:transition-all dark:duration-500 
            dark:text-white text-black font-bold font-mono m-8 text-2xl absolute pr-10'>
                I am a student at Mount Allison University majoring in Computer Science and minoring in Fine Art.
              </span>
            </div>
          
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={1.5} speed={0.1}
        onClick={() => ref.current.scrollTo(2)}
        className='-z-10'>

          <div className='grid grid-cols-3 gap-4 gap-y-12 pl-2 scale-75 justify-center place-items-center md:justify-center sm:scale-90 md:scale-90 lg:scale-90'>

            

                <div class="row-start-1 col-span-1 ">
                  <img src={fr} alt="French Flag" width="200" height="200" className='z-0'/>
                </div>

                <div class="row-start-1 col-start-2">
                  <img src={swiss} alt="Swiss Flag" width="200" height="150" className='z-0'/>
                </div>

                <div class="row-start-1 col-start-3 col-span-2">
                  <img src={nl} alt="Dutch Flag" width="200" height="300" className='z-0'/>
                </div>

                <div class="row-start-2 col-start-2 scale-150">
                  <img src={ca} alt="Canada Flag" width="200" height="300" className='z-0'/>
                </div>

          </div>
            <div className='flex justify-center pt-8 md:w-full'>
              <span 
              className='flex flex-wrap justify-center items-center transition-all duration-700 dark:transition-all 
              dark:duration-500 
            dark:text-white text-black font-bold font-mono m-8 text-2xl'>
                I have lived in France, Switzerland, the Netherlands and now Canada.
                  I am bilingual in English and French.
              </span>
            </div>
        </ParallaxLayer>

        <ParallaxLayer
          offset={2.5}
          speed={0.1}
          onClick={() => ref.current.scrollTo(3)}
          className='-z-10'
        >
          <span className='flex flex-wrap justify-center items-center transition-all duration-700 dark:transition-all 
          dark:duration-500 dark:text-white text-black font-bold font-mono m-8 md:text-center text-2xl'>
            There are a lot of interesting topics in Computer Science.
            My main interests however, are Full Stack development, online security and privacy, and Operating Systems.
            </span>
        </ParallaxLayer>

        <ParallaxLayer
          offset={3.4}
          speed={0.1}
          onClick={() => ref.current.scrollTo(0)}
          className='-z-10'
        >
          <span className='flex flex-wrap justify-center items-center transition-all duration-700 dark:transition-all 
          dark:duration-500 dark:text-white text-black font-bold font-mono m-8 text-2xl'>
            In my spare time I enjoy taking photographs, rowing and camping.
            </span>
        </ParallaxLayer>
      </Parallax>
    </div>
  
    );
};

export default About