import React from 'react';
import waves from '../assets/waves.mp4';
import sunrise from '../assets/sunrise.mp4';
import Typed from 'react-typed';
import useDarkMode from '../hooks/useDarkMode';

const Home = () => {

  const darkTheme = useDarkMode();
  const changeSource = (url) => {
    const video = document.getElementById("video");
    video.src = url;
    video.play();
  };

  return (
    <div className=' scrollbar-hide w-full h-screen mx-auto text-center flex flex-col justify-center'>
     <video
        src={sunrise}
        autoPlay
        loop
        muted
        className="object-cover pt-[50px] h-screen w-full absolute -z-10"
      />
      <p className='md:text-6xl sm:text-5xl text-2xl font-mono font-bold text-stone-800 py-5'>Mackenzie Chase</p>
      <div className='flex justify-center items-center text-stone-800'>
        
          <p className='md:text-5xl sm:text-4xl text-xl font-mono font-bold'>
            &gt;_
          </p>
          <Typed
          className='md:text-5xl sm:text-4xl text-xl font-mono font-bold md:pl-4 pl-2'
            strings={['Student', 'Developer', 'Photographer']}
            typeSpeed={150}
            backSpeed={130}
            loop
          />
        </div>
    </div>
  )
}

export default Home