import React, { useState } from 'react';
import {
  FaBars,
  FaTimes,
  FaMoon,
} from 'react-icons/fa';
import { BsSun } from 'react-icons/bs';
import { BiHome} from 'react-icons/bi';
import { AiTwotoneExperiment } from 'react-icons/ai';
import { MdPerson } from 'react-icons/md';
import { SiMinutemailer } from 'react-icons/si';
import { Link } from 'react-scroll';
import useDarkMode from '../hooks/useDarkMode';
import { useNavigate } from "react-router-dom";


const Navbar = () => {
  const navigate = useNavigate();
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);
  

  return (
    <div className='app navbar scrollbar-hide'>
      
       {/* home */}
       <button type='icon' className='navbar-icon' onClick={() => navigate('/home')}>
        <NavbarBarIcon icon={<BiHome size="2.0rem"/>}  text='home'/>
       </button>

      <div className='hidden md:flex'>
        <button type='icon' className='navbar-icon' onClick={() => navigate('/about')}>
          <NavbarBarIcon icon={<MdPerson size="1.7rem"/>}  text='about'/>
        </button>
        <button type='icon' className='navbar-icon' onClick={() => navigate('/experience')}>
          <NavbarBarIcon icon={<AiTwotoneExperiment size="1.7rem"/>}  text='experience'/>
        </button>
        <button type='icon' className='navbar-icon' onClick={() => navigate('/contact')}>
          <NavbarBarIcon icon={<SiMinutemailer size="1.7rem"/>}  text='contact'/>
        </button>
      
        <ThemeIcon/>
      </div>

       {/* Hamburger */}
      <div onClick={handleClick} className='md:hidden z-10'>
        {!nav ? <FaBars /> : <FaTimes />}
      </div>

      {/* Mobile menu */}
      <ul
        className={
          !nav
            ? 'hidden'
            : 'mobileMenu'
        }
      >
        <li className='py-6 text-4xl'>
          <Link>
            Home
          </Link>
        </li>
        <li className='py-6 text-4xl'>
          {' '}
          <Link>
            About
          </Link>
        </li>
        <li className='py-6 text-4xl'>
          {' '}
          <Link>
            Experience
          </Link>
        </li>
        <li className='py-6 text-4xl'>
          {' '}
          <Link>
            Contact
          </Link>
        </li>
      </ul>
    </div>
  );
};

const NavbarBarIcon = ({ icon, text}) => (
  <div className="icon group">
    {icon}
    <span class="icon-name group-hover:scale-100">
      &lt;{text}&frasl;&gt;
      </span>
  </div>
);
  

const ThemeIcon = () => {
  const [darkTheme, setDarkTheme] = useDarkMode();
  const handleMode = () => setDarkTheme(!darkTheme);
  return (
    <button type='theme' onClick={handleMode} className='theme-icon group'>
      <span className='theme-iconName group-hover:scale-100'>&lt;theme&frasl;&gt;</span>
      {darkTheme ? (<FaMoon size='1.3rem'/>) : (<BsSun size='1.3rem'/>)}
    </button>
  )
}

export default Navbar;
