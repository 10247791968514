import React, {useState} from 'react';

import HTML from '../assets/html.png';
import CSS from '../assets/css.png';
import JavaScript from '../assets/javascript.png';
import ReactImg from '../assets/react.png';
import Node from '../assets/node.png';
import Java from '../assets/java.png';
import Python from '../assets/python.png';
import C from '../assets/c.png';
import Git from '../assets/git.png';
import GitHub from '../assets/github.png';
import Tailwind from '../assets/tailwind.png';
import Mongo from '../assets/mongo.png';
import Qwirkle from '../assets/Qwirkle.png';
import This from '../assets/thisWebsite.png';
import TerminalBattleShip from '../assets/thisWebsite.png';
import SpaceCats from '../assets/thisWebsite.png';
import { FaJava, FaGitAlt, FaPython } from 'react-icons/fa';

const Experience = () => {
    const [technologies, setTechnologies] = useState([
        {icon: HTML,
            caption: "HTML"},
        {icon: CSS,
            caption: "CSS"},
        {icon: JavaScript,
            caption: "JavaScript"},
        {icon: ReactImg,
            caption: "React"},
        {icon: Node,
            caption: "NodeJS"
        },
        {icon: Java,
            caption: "Java"
        },
        {icon: Python,
            caption: "Python"
        },
        {icon: C,
            caption: "C"
        }, 
        {icon: Git,
            caption: "Git"
        },
        {icon: GitHub,
            caption: "Github"
        },
        {icon: Tailwind,
            caption: "Tailwind"
        },
        {icon: Mongo,
            caption: "MongoDB"
        }
    ])

    const [projects, setProjects] = useState([
        {icon: Qwirkle,
            caption: "Qwirkle",
            link: "https://github.com/wlmchase/comp4721-GP"},
        {icon: This,
            caption: "This website",
            link: "https://github.com/wlmchase/portfolio"},
        {icon: TerminalBattleShip,
            caption: "Terminal Battleship",
            link: "https://github.com/wlmchase?tab=repositories"},
        {icon: SpaceCats,
            caption: "Space Cats Fight Facism",
            link: "https://github.com/COMP3991-Fall-2022/Group-Project"},
    ])

  return (
    <div name='skills' className='app scrollbar-hide w-full h-fit pt-16 pb-16 bg-[#0a192f] text-black'>
      {/* Container */}
      <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-fit'>
          <div>
              <p className='text-4xl font-bold inline border-b-4 border-blue-500 '>Skills</p>
              <p className='py-4 text-xl pb-10'>These are the technologies I've worked with</p>
          </div>

          <div className='w-full h-fit grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8'>
          
          {technologies.map((tech, index) => (
            <div className='w-5/6 h-4/5 mx-auto my-auto hover:scale-110 duration-500 flex flex-col items-center justify-end'>
            <img className='mx-auto object-contain max-w-full' src={tech.icon} key={index} />
            <div className='my-4 mx-auto text-2xl'>{tech.caption}</div>
            </div>
            ))}
          </div>
      </div>
      <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-fit'>
          <div>
              <p className='text-4xl font-bold inline border-b-4 border-blue-500 '>Projects</p>
              <p className='py-4 text-xl'>These are the projects I've worked on </p>
          </div>

          <div className='w-full h-fit grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8'>
          {projects.map((project, index) => (
            <div  className='w-5/6 h-4/5 mx-auto my-auto hover:scale-110 duration-500 flex flex-col items-center justify-end'>
            <a href={project.link} target="_blank"><img className='mx-auto object-contain max-w-full' src={project.icon} key={index} />
            <div className='my-4 mx-auto text-2xl'>{project.caption}</div>
            </a>
            </div>
            ))}
          </div>
      </div>
      <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-fit'>
          <div>
              <p className='text-4xl font-bold inline border-b-4 border-blue-500 '>Work Experience</p>
              <p className='py-4 text-xl'>These are the places &amp; people I've worked with </p>
          </div>

          <div className='w-full h-fit grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8'>
          </div>
      </div>
    </div>
  );
};

export default Experience;