import React from "react";
import { Routes, Route } from 'react-router-dom';
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import About from "./pages/About";
import Experience from "./pages/Experience";
import Contact from "./pages/Contact";
import Footer from "./components/Footer";


function App() {
  return (
    <div className="scrollbar-hide">
      <Navbar/>
      <Routes className='scrollbar-hide'>
        {/* dashboard  */}
          <Route path="/" element={(<Home />)} />
          <Route path="/home" element={(<Home />)} />

          {/* pages  */}
          <Route path="/about" element={(<About />)} />
          <Route path="/experience" element={(<Experience />)} />
          <Route path="/contact" element={(<Contact />)} />

        </Routes>
      
    </div>
  );
}

export default App;